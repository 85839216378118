exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-solutions-fuel-wholesale-js": () => import("./../../../src/pages/solutions/fuel-wholesale.js" /* webpackChunkName: "component---src-pages-solutions-fuel-wholesale-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-logistics-js": () => import("./../../../src/pages/solutions/logistics.js" /* webpackChunkName: "component---src-pages-solutions-logistics-js" */),
  "component---src-pages-solutions-mining-js": () => import("./../../../src/pages/solutions/mining.js" /* webpackChunkName: "component---src-pages-solutions-mining-js" */),
  "component---src-pages-solutions-resources-js": () => import("./../../../src/pages/solutions/resources.js" /* webpackChunkName: "component---src-pages-solutions-resources-js" */),
  "component---src-pages-thank-you-[slug]-js": () => import("./../../../src/pages/thank-you/[slug].js" /* webpackChunkName: "component---src-pages-thank-you-[slug]-js" */)
}

